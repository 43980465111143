import wx from 'weixin-js-sdk' // 引入微信SDK
// import userApi from "../js/api/users"
// import router from '../router'
import axios from 'axios';

//判断是否是 微信浏览器
function isWxBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isWx) {
    return false;
  } else {
    let isWxWork = ua.match(/WxWork/i) == "wxwork";
    if (isWxWork) {
      return false;
    } else {
      return true;
    }
  }
}


const wxutil = { 
  init(){
    // router.afterEach((to, from) => {//router全局后置钩子函数
      if (isWxBrowser()) {//是微信浏览器
        axios.get({url:'https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wx758fabf72b07f2d6&secret=APPSECRET'}).then(res => {
          if (res.resCode == 0) {
            let data = res.resData;
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: data.appId, // 必填，公众号的唯一标识
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature: data.signature,// 必填，签名
              jsApiList: ['checkJsApi','updateAppMessageShareData','updateTimelineShareData'] // 必填，需要使用的JS接口列表，目前只用到分享
            });
  
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用  
              // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
              wx.updateAppMessageShareData({ 
                title: data.titile, // 分享标题
                desc: data.description, // 分享描述
                link: data.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致    http://test-logon.51czt.com
                imgUrl: data.logoPath, // 分享图标
                success: function () {
  
                }
              });
  
              wx.updateTimelineShareData({ 
                title: data.titile, // 分享标题
                link: data.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: data.logoPath, // 分享图标
                success: function () {
                  
                }
              });
              
            });
  
            wx.error(function(res){
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
              console.log("错误：" + res);
            });
  
          }
  
        }).catch(e => {
  
        });

      }
    // })

  },
}; 
export default wxutil;

