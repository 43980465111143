import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import wxutil from './wx'
import { Popup } from 'vant';
const app = createApp(App);
wxutil.init();

// setInterval(()=>{
//     wxutil.init();
// },7200)


app.use(Popup);
app.use(router);
app.mount('#app');
